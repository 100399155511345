<script setup lang="ts">
   const { t } = useI18n();
   const route = useRoute();
   const isClient = computed(() => {
      const routeName = (route.name as string) || '';
      return !routeName.includes('therapist');
   });
</script>
<template>
   <div class="projectBackground min-h-dvh flex flex-col justify-between">
      <div class="flex flex-col items-center">
         <div class="container px-8 md:px-4 lg:px-14">
            <LayoutUnAuthHeader></LayoutUnAuthHeader>
            <div class="my-8 md:my-16">
               <div class="flex flex-col md:flex-row">
                  <div class="w-full md:w-1/2 mb-10">
                     <div
                        class="text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-medium md:w-2/3"
                     >
                        {{ t('signIn.welcome') }}
                     </div>
                     <div
                        class="text-gray-200 mt-1 md:mt-5 text-sm md:text-lg lg:text-xl md:w-5/6 lg:w-2/3"
                     >
                        {{
                           isClient ?
                              t('signIn.clientDescription')
                           :  t('signIn.therapistDescription')
                        }}
                     </div>
                  </div>
                  <div class="w-full flex justify-center md:w-1/2">
                     <NuxtPage />
                  </div>
               </div>
            </div>
         </div>
      </div>
      <LayoutFooter></LayoutFooter>
      <WhatappContact :is-right="true"></WhatappContact>
   </div>
</template>

<style scoped>
   .projectBackground {
      background-image: url('@/assets/image/background-gradient-left.png'),
         url('@/assets/image/background-gradient-right.png');
      background-repeat: no-repeat;
      background-position:
         0 0,
         100% 0;
   }
</style>
